<template>
    <Text as="h5" size="f-5" weight="600" style="line-height: 1.75rem">
        {{ alert?.title }}
    </Text>
    <Spacer height="1.5rem" />
    <Text as="p" size="f-8">
        Ads are no longer running in <Ent>{{ alert?.accountName }}</Ent
        >.
    </Text>
    <Spacer height="1rem" />
    <Text as="p" size="f-8">
        <span v-if="inZeroSequence">
            On {{ month }} your account received zero impressions. Based on previous data, we would
            have expected around <b><Number :value="expected" /></b> impressions on this day. The
            actual number of impressions for this day is highlighted in the graph below.
            <Spacer height="1rem" />
            Check Google Ads for billing issues, disapproved ads, or any other problems that might
            be responsible for this change.
            <Spacer height="1rem" />
            If this behaviour is expected, feel free to ignore this alert.
        </span>
        <span v-else>
            On {{ month }}, spend stopped and impressions fell from
            <b><Number :value="impressions" /></b> to <b>0</b>. Based on previous data, we expected
            roughly <b><Number :value="expected" /></b> impressions for this day. The actual number
            of impressions for this day is highlighted in the graph below.
            <Spacer height="1rem" />
            Check Google Ads for billing issues, disapproved ads, or any other problems that might
            be responsible for this change.
            <Spacer height="1rem" />
            If this behaviour is expected, feel free to ignore this alert.
        </span>
    </Text>
    <Spacer height="2rem" />
    <div class="chart-container">
        <LineChart
            :series="chartData"
            :areas="redArea"
            :show-zero="true"
            :chart-height="180"
            disable-fallback
        />
    </div>
    <Spacer height="2rem" />
    <AlertNotes :alert-date="isoDate" :account-id="alert?.accountId ?? ('' as Account.ID)" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Text, LineChart, Spacer, Number, LineChartTypes } from '@opteo/components-next'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import subtractDates from 'date-fns/sub'
import { Account, Alerts } from '@opteo/types'
import sortBy from 'lodash-es/sortBy'

import AlertNotes from '@/components/notes/AlertNotes.vue'
import Ent from '@/components/util/Ent.vue'
import { useAlert } from '@/composition/alerts/useAlert'

export default defineComponent({
    name: 'ImpressionsFlatline',
    components: {
        AlertNotes,
        Text,
        LineChart,
        Spacer,
        Number,
        Ent,
    },
    setup() {
        const { alert, isCampaignLevel } = useAlert<Alerts.FlatlineBody, Alerts.FlatlineChartData>()

        if (!alert.value) {
            throw new Error('An alert ID must be set before initializing this alert.')
        }

        const isoDate = parseISO(alert.value.body.date)

        const inZeroSequence = alert.value.body.in_zero_sequence

        const dayNumber = format(parseISO(alert.value.body.date), 'do')
        const month = format(parseISO(alert.value.body.date), 'LLLL do')

        const impressions = alert.value.body.day_before_yesterday.impressions
        const expected = alert.value.body.expected

        const chartData: LineChartTypes.LineChartSeries[] = [
            {
                name: 'Impressions',
                items: sortBy(alert.value?.chart, row => parseISO(row.day).valueOf())
                    .map(row => {
                        return {
                            x: parseISO(row.day),
                            y: row.impressions,
                        }
                    })
                    .slice(-11),
            },
        ]

        const redArea = [
            { axis: 'x', start: subtractDates(isoDate, { days: 1 }), end: isoDate, color: 'red' },
        ]

        return {
            alert,
            isCampaignLevel,
            chartData,
            inZeroSequence,
            dayNumber,
            month,
            impressions,
            expected,
            isoDate,
            redArea,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.chart-container {
    @include container;
    @include pa-24;
}
</style>
